import React, { Fragment, useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import {
  useFetchAllbanUnbanMutation,
} from "../../../features/tenant/banUnban/banUnbanApi";

const BanUnban = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const location = useLocation();
  const navigate = useNavigate();


  const [
    FetchAllbanUnban,
    {
      data: FetchAllbanUnbanData,
      error: FetchAllbanUnbanError,
      isLoading: FetchAllbanUnbanIsLoading,
      isError: FetchAllbanUnbanIsError,
    },
  ] = useFetchAllbanUnbanMutation();

  const [
    FetchAllbanUnbanCsv,
    {
      data: FetchAllbanUnbanCsvData,
      error: FetchAllbanUnbanCsvError,
      isLoading: FetchAllbanUnbanCsvIsLoading,
      isError: FetchAllbanUnbanCsvIsError,
    },
  ] = useFetchAllbanUnbanMutation();
  const [prevPage, setPrevPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];

  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    limit: 10,
    offset: 0,
    user_mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);


  useEffect(() => {
    fetchFetchAllbanUnbanFunction();
    fetchFetchAllbanUnbanCsvFunction();
  }, [location.pathname, limit, offset, userTypesArray]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };


  const fetchFetchAllbanUnbanFunction = (row) => {
    FetchAllbanUnban({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchFetchAllbanUnbanCsvFunction = (row) => {
    const modifiedRequest = {
        ...request,
        limit: null,
        offset: null,
      };
    FetchAllbanUnbanCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const clearFilters = () => {
    setRequest(initialRequest);
  };




  const columns1 = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => (
        <div>
          <h2
            onClick={() =>
              navigate(`${row.id}`, {
                state: row,
              })
            }
            className=" underline text-blue-600"
          >
            {row.user_name}
          </h2>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Mobile",
      cell: (row) => row.user_mobile,
      sortable: true,
    },
    {
      name: "UserType",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Block/Unblock Date",
      cell: (row) => (
        <div className="flex">
          <span className="mr-2 w-fit">
            {new Date(row.updated_at).toLocaleDateString()}
          </span>

          <span className="w-fit">
            {new Date(row.updated_at).toLocaleTimeString()}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Status",
      cell: (row) => "Blocked",
    },
    {
      name: "Remarks",
      cell: (row) => row.remarks,
      minWidth: "200px",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Ban/Unban list
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between items-start">
              {FetchAllbanUnbanCsvData && FetchAllbanUnbanCsvData?.body?.banUnbanList && (
                <ExportCSV
                  data={FetchAllbanUnbanCsvData && FetchAllbanUnbanCsvData?.body?.banUnbanList}
                  columnsToExport={[
                    "user_name",
                    "user_mobile",
                    "user_type",
                    "updated_at",
                    "Status",
                    "remarks"
                  ]}
                />
              )}
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.user_mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, user_mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchFetchAllbanUnbanFunction();
                            fetchFetchAllbanUnbanCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
              <DataTableComponent
                columns={columns1}
                data={
                  FetchAllbanUnbanData && FetchAllbanUnbanData.body.banUnbanList
                }
                totalRows={FetchAllbanUnbanData?.body?.total_ban_unban_history}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>

   
      </div>
    </>
  );
};

export default BanUnban;
