import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useGetAllGullakPointsMutation } from "../../../features/tenant/ModenikApi/modenikApi";
import toast from "react-hot-toast";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
  
const monthsArr = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
  { id: 12, name: "Q1" },
  { id: 13, name: "Q2" },
  { id: 14, name: "Q3" },
  { id: 15, name: "Q4" },
];
const yearArr = [
  { id: 23, name: "2023-24" },
  { id: 24, name: "2024-25" },
  { id: 25, name: "2025-26" },
];

const GullakPoints = () => {
  const navigate = useNavigate();

  const [
    fetchPointsHistory,
    { data: pointsHistoryData, error: pointsHistoryError, isLoading: pointsHistoryIsLoading },
  ] = useGetAllGullakPointsMutation();

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];


  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
    mobile: "",
    start_date: "",
    end_date: currentDate,
    user_type_id: 3,
    type: "",
    user_id: "",
    month: "",
    year: ""
  };
  const [request, setRequest] = useState(initialRequest);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.name === "" &&
      request.mobile === "" &&
      request.start_date === "" &&
      request.type === "" &&
      request.user_id === "" &&
      request.month === "" &&
      request.year === "" &&
      request.end_date === currentDate
    ) {
      fetchPointsHistoryFunction();
      // fetchCsvPointsScanHistoryFunction();
    }
  }, [request]);


  useEffect(() => {
    if (limit != 10 || offset != 0)
      fetchPointsHistoryFunction();
    // fetchCsvPointsScanHistoryFunction();
  }, [limit, offset]);

  const fetchPointsHistoryFunction = () => {
    fetchPointsHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };



  useEffect(() => {
    if (pointsHistoryData && pointsHistoryData?.body) {
      console.log("success history", pointsHistoryData);
      setMyList(pointsHistoryData.body && pointsHistoryData.body.data ? pointsHistoryData.body.data : []);
      setTotalRows(pointsHistoryData.body && pointsHistoryData.body.count ? pointsHistoryData.body.count : 0);
    }

    if (pointsHistoryError) {
      toast.error("Something went wrong", { id: "pointsHistoryError" })
    }

  }, [pointsHistoryData, pointsHistoryError]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },

    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "SAP Code",
      cell: (row) => row.user_id,
      sortable: true,
      width: "120px",
    },

    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Scheme Name",
      cell: (row) => row.cause && row.cause.type,
      sortable: true,
    },

    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "Month",
      cell: (row) => row.month,
      sortable: true,
    },
    {
      name: "Year",
      cell: (row) => row.year,
      sortable: true,
    },
    {
      name: "Added On",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },


  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Gullak Points History
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-100 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">SAP Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="SAP Code..."
                        required
                        value={request.user_id}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, user_id: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Scheme Name</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Scheme Name ..."
                      required
                      value={request.type}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, type: newValue };
                        });
                      }}
                    />
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Month</label>
                      <select id="month"  className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp" onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            month: newValue,
                          };
                        });
                      }}
                        value={request.month}>
                           <option key={-1} value={""} selected >
                           Select Month
                           </option>
                        {monthsArr.map((month) => (
                          <option  key={month.id} value={month.name}>
                            {month.name}
                          </option>
                        ))}
                      </select>

                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label id="year" className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Year</label>
                      <select id="year"  className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp" onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            year: newValue,
                          };
                        });
                      }}
                        value={request.year}>
                            <option key={-1} value={""} selected>
                           Select Year
                           </option>
                        {yearArr.map((year) => (
                          <option key={year.id} value={year.name}>
                            {year.name}
                          </option>
                        ))}
                      </select>

                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.start_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.end_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                 
                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          fetchPointsHistoryFunction();
                          // fetchCsvPointsScanHistoryFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {pointsHistoryIsLoading && <CircleLoaderComponent />}
                <DataTableComponent
                  columns={columns}
                  // title={""}
                  data={
                    myList
                  }
                  // loading={loading}
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  <div className="flex m-2 space-x-2 justify-end"></div>
                </DataTableComponent>
                {true && (
                  <div
                    className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                    id="box_btn"
                  >
                    <div className="text-right">
                      <Menu className="relative inline-block text-left">
                        <div>
                          <Menu.Button
                            onClick={() => navigate(`add`)}
                            className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                            style={{ backgroundColor: `${primaryColor["700"]}` }}
                          >
                            <HiOutlinePlus className="w-full h-full p-3" />
                          </Menu.Button>
                        </div>
                      </Menu>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GullakPoints;
