import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { HiArrowsUpDown, HiOutlineCheck } from "react-icons/hi2";

// const people = [
//   { id: 1, name: "Wade Cooper" },
//   { id: 2, name: "Arlene Mccoy" },
//   { id: 3, name: "Devon Webb" },
//   { id: 4, name: "Tom Cook" },
//   { id: 5, name: "Tanya Fox" },
//   { id: 6, name: "Hellen Schmidt" },
// ];

export default function MultiSelectComponent({
  title,
  selected,
  setSelected,
  dataArr,
  withAvatar,
  placeholder,
  required=true
}) {
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? dataArr
      : dataArr.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );
  return (
    <div className="flex z-50 justify-center items-center w-full ">
      <div className="w-full">
        <Combobox value={selected} onChange={setSelected} multiple>
          <Combobox.Label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-0">
           {title} {required && " * "}
          </Combobox.Label>
          <div className="relative ">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="shadow-sm capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                displayValue={(data) =>
                  data.map((each) => each.name).join(", ")
                }
                placeholder={placeholder}
                contentEditable={false}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <HiArrowsUpDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 z-10 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredPeople.map((each) => (
                    <Combobox.Option
                      key={each.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-teal-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={each}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={` capitalize block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {each.name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-teal-600"
                              }`}
                            >
                              <HiOutlineCheck
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
