import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useFetchAllBatchMutation } from "../../../features/tenant/batch/batchApi";
import { useAddQrCodeForCgMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import { useAddBatchMutation } from "../../../features/tenant/batch/batchApi";
import { toast } from "react-hot-toast";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import { useFetchSubcategoriesMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";

const CgGenerateQr = () => {
  const [qrType, setQrType] = useState(
    JSON.parse(sessionStorage.getItem("companyProfile")).qr_type
  );

  const [batch, setBatch] = useState("");

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    id: -1,
    name: "",
  });

  useEffect(()=>{
    console.log(selectedProduct,"HERE");

  },[selectedProduct])

  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: -1,
    name: "",
  });

  const [qty, setQty] = useState(null);

  const navigate = useNavigate();

  const [date, setDate] = useState();
  function generateShortCode(selectedProduct) {
    // Extract classification and parent_category_name from selectedProduct
    const classification = selectedProduct.classification;
    const parentCategory = selectedProduct.parent_category_name;

    // Determine the short code based on parent_category_name
    let shortCode;
    if (parentCategory === "MOTOR") {
      shortCode = classification.substring(0, 2).toUpperCase();
    } else {
      shortCode = classification.substring(0, 3).toUpperCase();
    }

    return shortCode;
  }

  useEffect(() => {
    // const data=date.split("-")
    if (date) {
      const data = date.split("-");
      const yearMonth = getLetterCode(data[0], data[1]);
      // const productCode=selectedProduct.classification;
      const shortCode = selectedProduct.image_ref;
      // console.log(productCode,"ProductCode")
      setBatch(yearMonth + shortCode);
      console.log(yearMonth + shortCode);
    }
    // console.log(date,"DATA");
  }, [date, selectedProduct]);

  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  useEffect(() => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  function getLetterCode(year, month) {
    // Define the alphabet
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Calculate the index for the year code
    const yearIndex = ((year - 2000) % 26) - 1;
    const yearCode = alphabet[yearIndex];

    // Calculate the index for the month code
    const monthIndex = (month - 1) % 12;
    const monthCode = alphabet[monthIndex];

    // Return the combined code
    return yearCode + monthCode;
  }

  useEffect(() => {
    if (productsData) {
      if (productsData?.body?.products) {
        
        const products = productsData.body.products.map((e) => {
          return {
            ...e,
            product_name: e.name, 
            name: e.product_code  
          };
        });
        console.log(products);
        setProductList(products);
      }
    }
  }, [productsData]);

  const [
    addQrCode,
    {
      data: QrCodeData,
      isLoading: QrCodeIsLoading,
      isError: QrCodeIsError,
      error: QrCodeError,
    },
  ] = useAddQrCodeForCgMutation();

  const [
    addBatch,
    {
      data: batchData,
      isLoading: batchIsLoading,
      isError: batchIsError,
      error: batchError,
    },
  ] = useAddBatchMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedDate = date.split("-");
    if (selectedProduct.id !== -1) {
      const data = {
        brand: selectedProduct.product_name,
        name: batch,
        status: "1",
        month: selectedDate[1],
        year: selectedDate[0],
      };
      addBatch({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
      });
    } else {
      toast.error("Select a product", { id: "select-product" });
    }
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    toast.dismiss();

    if (selectedProduct.id === -1) {
      toast.error("Select a product", { id: "select-product" });
      return;
    }

    if (qty <= 0) {
      toast.error("Quantity should be more than 0", { id: "qty-error" });
      return;
    }

    if (qrType == "2" && selectedProduct.qty === 0) {
      toast.error("Product Qty is Invalid", { id: "invalid-product-qty" });
      return;
    }

    if (qrType == "2" && qty > 0 && qty % selectedProduct.qty !== 0) {
      toast.error(
        "Invalid Qr count, count must be in multiples of product quantity",
        { id: "invalid-qty" }
      );
      return;
    }

    handleSubmit(e);
  };

  useEffect(() => {
    if (batchData) {
      const data = {
        batch_id: batchData?.body?.batch_id,
        batch_code: batchData?.body?.name,
        product_code: selectedProduct.product_code,
        product_name: selectedProduct.product_name,
        qty: qty,
        qr_type: qrType,
      };
      addQrCode({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
      });
    } else if (batchError?.status == "409") {
      const data = {
        batch_id: batchError?.data?.Error?.batch_id,
        batch_code: batchError?.data?.Error?.name,
        product_code: selectedProduct.product_code,
        product_name: selectedProduct.product_name,
        qty: qty,
        qr_type: qrType,
      };
      addQrCode({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
      });
    } else if (batchError) {
      toast.error("Internal Server error", { id: "select-product" });
    }
  }, [batchData, batchIsError, batchError]);

  useEffect(() => {
    // console.log("selected subcategory ", selectedProductCategory);
    if (productsData && selectedProductCategory.id !== -1) {
      if (productsData?.body?.products) {
        // console.log(productsData.body.products);
        const arr = productsData.body.products.filter(
          (product) => product.category_id == selectedProductCategory.id
        );
        // console.log(arr);
        setProductList(arr);
        setSelectedProduct({
          id: -1,
          name: "",
        });
      }
    }
  }, [selectedProductCategory]);

  useEffect(() => {
    const id = "qrGenerate";
    if (QrCodeIsLoading) {
      toast.loading("Generating QR", { id: id });
    }

    if (QrCodeData) {
      toast.success("Generated Successfully", {
        id: id,
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/printQr`);
      }, 200);
    }

    if (QrCodeError) {
      toast.error("Some Error Occurred", {
        id: id,
      });
    }
  }, [QrCodeData, QrCodeError, QrCodeIsLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Qr Code Generation
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <form className="">
                <div className="w-full flex justify-center space-x-4">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  <div className="mb-6 bottom-2 w-1/4">
                    <SelectComponentWithSearch
                      title={"Product"}
                      selected={selectedProduct}
                      setSelected={setSelectedProduct}
                      dataArr={productList}
                      placeholder={"Select Product"}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Month-Year"}
                      type={"month"}
                      value={date}
                      setValue={setDate}
                      required={true}
                    />
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Batch Name"}
                      type={"text"}
                      value={batch}
                      setValue={setBatch}
                      required={true}
                      placeholder={"Enter Quantity"}
                    />
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                  Qr Type *
                    <select
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto"
                      value={qrType}
                      onChange={(e) => setQrType(e.target.value)}
                      required
                    >
                      <option value="">Please select a value</option>
                      <option value={1}>Child</option>
                      <option value={2}>Parent Child</option>
                    </select>
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Quantity"}
                      type={"number"}
                      value={qty}
                      setValue={setQty}
                      required={true}
                      placeholder={"Enter Quantity"}
                    />
                  </div>
                </div>
                {!QrCodeIsLoading && (
                  <div className="flex justify-end mt-2">
                    <button
                      // type="submit"
                      onClick={(e) => handleGenerate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Generate
                    </button>
                  </div>
                )}
              </form>
            </div>{" "}
            <div>
              {selectedProduct.id === -1 ? (
                <> N/A</>
              ) : (
                <div className="max-w-3xl my-2 mx-auto flex bg-white  rounded overflow-hidden shadow-lg">
                  <div className="p-0">
                    {/* {JSON.stringify(selectedProduct)} */}
                    {selectedProduct.images && (
                      <img
                        src={
                          selectedProduct.images.length > 0 &&
                          selectedProduct.images[0]
                        }
                        alt={selectedProduct?.name}
                        className="w-full h-24 object-cover"
                      />
                    )}
                    <div className="px-4 py-2">
                      <div className="font-bold text-xl mb-2">
                        {selectedProduct?.name}
                      </div>
                      <p className="text-gray-700 text-base">
                        {selectedProduct?.description}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="px-4 py-2">
                      <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                        {selectedProduct?.category_name}
                      </span>
                      <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                        {selectedProduct?.brand_name}
                      </span>
                    </div>
                    <div className="px-4 py-2">
                      <span className="inline-block capitalize bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                        {selectedProduct?.unit_name} (
                        {selectedProduct?.uom_value} {selectedProduct?.uom_key})
                      </span>
                      <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                        Qty : {selectedProduct?.qty}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="px-4 py-2">
                      <p className="text-gray-700 text-sm">
                        Product Code: {selectedProduct?.product_code}
                      </p>
                      <p className="text-gray-700 text-sm">
                        Status:{" "}
                        {selectedProduct?.status === "1"
                          ? "Active"
                          : "Inactive"}
                      </p>
                      <p className="text-gray-700 text-sm">
                        Points Active:{" "}
                        {selectedProduct?.points_active === "1" ? "Yes" : "No"}
                      </p>
                      <p className="text-gray-700 text-sm">
                        Warranty Activation Period:{" "}
                        {selectedProduct?.warranty_activation_period} days
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <p className="text-gray-700 text-sm">
                        Created by: {selectedProduct?.created_by_name} on{" "}
                        {new Date(
                          selectedProduct?.created_at
                        ).toLocaleDateString()}
                      </p>
                      <p className="text-gray-700 text-sm">
                        Last updated by: {selectedProduct?.updated_by_name} on{" "}
                        {new Date(
                          selectedProduct?.updated_at
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CgGenerateQr;
