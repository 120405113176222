import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useAddQrMappingMutation } from "../../../features/tenant/QrMapping/QrMappingApi";
import { downloadCSV } from "../../../utils/downloadCSV";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

const sampleDataQrBulkUpload = [
  {
    product_code: "FS-8400 STD SSS",
    batch_running_code: "DCCDA1001OOPL",
  },
];

function validateAndCleanObjects(objects) {
  const requiredKeysSet = new Set(["product_code", "batch_running_code"]);

  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key.trim()]);
  }

  const cleanedObjects = [];

  console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();

      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          console.log("missing required key", object, key);
          valid = false;
          break;
        } else {
          newObject[key] = object[key];
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    cleanedObjects.push(newObject);
  }

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}

const QrProductMapping = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [array, setArray] = useState([]);
  const [startBatchRunningCode, setStartBatchRunningCode] = useState();
  const [endBatchRunningCode, setEndBatchRunningCode] = useState();
  const [
    addQrMapping,
    {
      data: addQrMappingData,
      error: addQrMappingError,
      isLoading: addQrMappingIsLoading,
    },
  ] = useAddQrMappingMutation();
  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    id: -1,
    name: "",
  });

  useEffect(() => {
    if (productsData?.body?.products) {
      setProductList(productsData?.body?.products);
    }
  }, [productsData]);

  useEffect(() => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const handleOnSubmit = (e) => {
   

    e.preventDefault();
    if(!startBatchRunningCode||!endBatchRunningCode)
    {
        toast.error("Please fill all the fields");
        return;
    }

    if(selectedProduct.id==-1)
        {
            toast.error("Please Select a product");
            return;
        }

        console.log(startBatchRunningCode >= endBatchRunningCode);
        if (startBatchRunningCode >= endBatchRunningCode) {
            toast.error("Start batch running code must be smaller than end batch running code");
            return;
        }
        toast.loading("Processing your data...");
        const userTenant = JSON.parse(sessionStorage.getItem("userTenant"));
    addQrMapping({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {startBatchRunningCode,endBatchRunningCode,product:selectedProduct?.name,user_id:userTenant.id,name:userTenant.name},
      apikey: JSON.parse(sessionStorage.getItem("companyProfile")).api_key,
    });
  };

  useEffect(() => {
    if (addQrMappingData) {
      toast.dismiss();
      toast.success("Saved Successfully", {
        id: "addQrMappingData",
      });
      console.log("addQrMappingData", addQrMappingData);
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/printQr`);
      }, 400);
    }

    if (addQrMappingError) {
      toast.dismiss();
      toast.error("Something went wrong");
      console.log("addQrMappingError", addQrMappingError);
    }
  }, [addQrMappingData, addQrMappingError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Bulk Update Qr Product
            <LinksComponent />
          </div>

          <div className="mx-9 mt-10">
            <div className="flex space-x-3"></div>
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className=" flex flex-col justify-center align-middle w-full">
                <div className="">
                  <div className="text-center ">
                    <div className="w-full flex justify-center items-center space-x-4">
                      <div className="mb-6 bottom-2 w-1/4">
                        <SelectComponentWithSearch
                          title={"Product"}
                          selected={selectedProduct}
                          setSelected={setSelectedProduct}
                          dataArr={productList}
                          required={true}
                          placeholder={"Select Product"}
                        />
                      </div>
                      <div className="mb-6 bottom-2 w-1/4">
                        <InputComponent
                          name={"Starting Batch Running Code"}
                          type={"text"}
                          value={startBatchRunningCode}
                          setValue={setStartBatchRunningCode}
                          required={true}
                        />
                      </div>
                      <div className="mb-6 bottom-2 w-1/4">
                        <InputComponent
                          name={"End Batch Running Code"}
                          type={"text"}
                          value={endBatchRunningCode}
                          setValue={setEndBatchRunningCode}
                          required={true}
                        />
                      </div>
                      <div className="flex justify-end mt-2">
                    <button
                      onClick={(e) => handleOnSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Submit
                    </button>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrProductMapping;
