import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { States } from "../../../utils/getStates";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {
  useAddAppCampaignMutation,
  useUpdateAppCampaignByIdMutation,
} from "../../../features/tenant/appCampaign/appCampaignApi";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { egyptStates } from "../../../utils/ultimatrueStates";
const status = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Inactive",
  },
];

const type = [
  {
    id: 1,
    name: "Information",
  },
  {
    id: 2,
    name: "Feedback",
  },
];
const canUserHide = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 2,
    name: "No",
  },
];
const AddCampaign = () => {
  const [selectedCanUserHide, setSelectedCanUserHide] = useState(
    canUserHide[0]
  );


  const [allStates, setAllStates] = useState(JSON.parse(sessionStorage.getItem("vendorData")).tenant_name === "ultimatrue" ? egyptStates : [{ id: "ALL", name: "All" }, ...States]);

  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedType, setSelectedType] = useState(type[0]);
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [userTypes, setUserTypes] = useState([]);
  const [userTypesArray, setUserTypesArray] = useState([]);
  const [elements, setElements] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lableName, setLableName] = useState("");
  const [name, setName] = useState("");
  const [inputLength, setInputLength] = useState("");
  const [inputType, setInputType] = useState("");
  const [required, setRequired] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [usersTypes, setUsersTypes] = useState([]);
  const [newUserValue, setNewUserValue] = useState("");
  const [isFeedBack, setIsFeedback] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [title, setTitle] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [webLink, setWebLink] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [campaignId, setCampaignId] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [
    getAppUserTypes,
    {
      data: getAppUserTypesData,
      isLoading: getAppUserTypesIsLoading,
      error: getAppUserTypesIsError,
      isError: getAppUserTypesError,
    },
  ] = useGetAppUserTypesMutation();
  useEffect(() => {
    const data = location.state;
    console.log(data, "data");
    if (data) {
      setIsUpdate(true);
    }

    if (data?.id) {
      setCampaignId(data.id);
    }
    if (data && data.can_user_hide == true) {
      setSelectedCanUserHide(canUserHide[0]);
    } else {
      setSelectedCanUserHide(canUserHide[1]);
    }
    if (data && data.type == "Information") {
      setSelectedType(type[0]);
    } else  {
      setSelectedType(type[1]);
    }
    if (data?.start_date) {
      const startDateObj = data.start_date.slice(0, 10);
      setStartDate(startDateObj);
    }
    if (data?.end_date) {
      const endDateObj = data.end_date.slice(0, 10);
      setEndDate(endDateObj);
    }

    if (data?.title) {
      setTitle(data.title);
    }
    if (data?.description) {
      setDescription(data.description);
    }
    if (data?.web_link) {
      setWebLink(data.web_link);
    }
    if (data?.video_title) {
      setVideoTitle(data?.video_title);
    }
    if (data?.video_link) {
      setVideoLink(data?.video_link);
    }
    if (data?.user_type?.length && data?.user_type_id?.length&&getAppUserTypesData&&getAppUserTypesData?.body) {
      let arr=[]
      getAppUserTypesData?.body.forEach((user) => {
        if (data.user_type_id.includes(Number(user.user_type_id))) {
          arr.push(user);
        }
      });
      setUserTypesArray(arr);
    }
  }, [location.state,getAppUserTypesData]);
  const [
    addAppCampaign,
    {
      data: addAppCampaignData,
      isLoading: addAppCampaignIsLoading,
      isError: addAppCampaignIsError,
      error: addAppCampaignError,
    },
  ] = useAddAppCampaignMutation();

  useEffect(() => {
    if (addAppCampaignData) {
      toast.success("App Campaign Added Successfully !!");
      navigate(-1);
    }
    if (addAppCampaignError) {
      toast.error("Error in Creating Wheel Campaign");
    }
  }, [addAppCampaignData, addAppCampaignError]);

  useEffect(() => {
    if (selectedType.name === "Feedback") {
      setIsFeedback(true);
      return;
    }
    setIsFeedback(false);
  }, [selectedType]);

  useEffect(() => {
    const handleNewElementAddition = () => {
      let newElementObj = {
        label: lableName,
        name: name,
        type: inputType,
        required: required,
        maxLength: inputLength,
        options: usersTypes,
      };

      let oldOptions = elements;
      oldOptions.push(newElementObj);
      setElements(oldOptions);
      console.log("added");

      console.log(oldOptions);
    };
    if (lableName !== "" && inputType !== "" && inputLength !== "") {
      handleNewElementAddition();
      setLableName("");
      setInputLength("");
      setRequired(false);
      setInputType("");
      setUsersTypes([]);
    }
  }, [reRender]);

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
  };

  const removeFromUserTypes = (e, key) => {
    console.log(key);
    let oldValues = [...usersTypes];
    oldValues = oldValues.filter((user) => user != key);
    setUsersTypes(oldValues);
  };

  const handleDeleteElement = (e, eIdx) => {
    let oldElements = elements;
    oldElements = oldElements.filter((ele, idx) => idx !== eIdx);
    setElements(oldElements);
    setReRender((old) => !old);
  };

  const addCampaign = () => {
    var template = JSON.stringify(elements);
    const userTypeIds = userTypesArray.map((obj) => obj.user_type_id);
    const userTypeNames = userTypesArray.map((obj) => obj.user_type);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("template", template);
    formData.append("videoLink", videoLink);
    formData.append("videoTitle", videoTitle);
    formData.append("webLink", webLink);
    formData.append("image", image);
    formData.append("canUserHide", selectedCanUserHide.name == "Yes" ? true : false );
    formData.append("status", selectedStatus.id);
    formData.append("type", selectedType.name);
    formData.append("user_type", JSON.stringify(userTypeNames));
    formData.append("user_type_id", JSON.stringify(userTypeIds));
    formData.append("states", JSON.stringify(selectedStates));

    addAppCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: formData,
    });
  };

  const [
    updateAppCampaign,
    {
      data: updateAppCampaignData,
      isLoading: updateAppCampaignIsLoading,
      isError: updateAppCampaignIsError,
      error: updateAppCampaignError,
    },
  ] = useUpdateAppCampaignByIdMutation();

  const updateCampaign = () => {
    var template = JSON.stringify(elements);
    const userTypeIds = userTypesArray.map((obj) => obj.user_type_id);
    const userTypeNames = userTypesArray.map((obj) => obj.user_type);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("template", template);
    formData.append("videoLink", videoLink);
    formData.append("videoTitle", videoTitle);
    formData.append("webLink", webLink);
    formData.append("image", image);
    formData.append("canUserHide", selectedCanUserHide.name == "Yes" ? true : false );
    formData.append("status", selectedStatus.id);
    formData.append("type", selectedType.name);
    formData.append("user_type", JSON.stringify(userTypeNames));
    formData.append("user_type_id", JSON.stringify(userTypeIds));
    formData.append("states", JSON.stringify(selectedStates));

    updateAppCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: formData,
      id: campaignId,
    });
  };

  useEffect(() => {
    if (updateAppCampaignData) {
      toast.success("App Campaign Updated Successfully !!");
      navigate(-1);
    }
    if (updateAppCampaignError) {
      toast.error("Error in Updating Wheel Campaign");
    }
  }, [updateAppCampaignData, updateAppCampaignError]);



  useEffect(() => {
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);
  useEffect(() => {
    if (getAppUserTypesData?.body) {
      // setUserTypesArray(getAppUserTypesData.body[0]);
      setUserTypes(getAppUserTypesData.body);
    }
  }, [getAppUserTypesData]);
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Add Campaign
          <LinksComponent />
        </div>
<div className=" p-3">
        <div className="p-3 bg-white shadow rounded-lg">
          <div className="w-full flex justify-center space-x-3 ">
            <div className="mb-6 w-1/3 ">
              <InputComponent
                name={"Campigning Name*"}
                type={"text"}
                value={title}
                setValue={setTitle}
                required={true}
              />
            </div>
            <div className="mb-6 w-1/3 slctbox">
              <label
                htmlFor="image"
                className="block text-sm font-medium  text-gray-900 mb-2 "
              >
                Image From Campaigning
              </label>

              <input
                type="file"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2   h-auto"
                onChange={(e) => {
                  const imageFiles = e.target.files;
                  const imageFilesLength = imageFiles.length;
                  if (imageFilesLength && imageFiles.length) {
                    console.log(imageFiles, "image");
                    setImage(imageFiles[0]);
                  }
                }}
              />
            </div>
            <div className="mb-6 w-1/3">
              <InputComponent
                name={"Video Name"}
                type={"text"}
                value={videoTitle}
                setValue={setVideoTitle}
                required={true}
              />
            </div>
          </div>
          <div className="w-full flex justify-center space-x-3 ">
            <div className="mb-6  w-1/3  ">
              <InputComponent
                name={"Video Link"}
                type={"text"}
                value={videoLink}
                setValue={setVideoLink}
                required={true}
              />
            </div>
            <div className="mb-6  w-1/3 ">
              <InputComponent
                name={"Campaigning Web Redirection"}
                type={"text"}
                value={webLink}
                setValue={setWebLink}
                required={true}
              />
            </div>
            <div className="mb-6  w-1/3 ">
              <InputComponent
                name={"Campaigning Start Date*"}
                type={"date"}
                value={startDate}
                setValue={setStartDate}
                required={true}
              />
            </div>
          </div>
          <div className="w-full flex justify-center space-x-3 ">
            <div className="mb-6  w-1/3 ">
              <InputComponent
                name={"Campaigning End Date*"}
                type={"date"}
                value={endDate}
                setValue={setEndDate}
                required={true}
              />
            </div>
            {userTypes.length > 0 && (
              <div className="mb-6  w-1/3 slctbox1">
                <MultiSelectComponent
                  title={"Campaigning Applicable For"}
                  selected={userTypesArray}
                  setSelected={setUserTypesArray}
                  dataArr={userTypes}
                />
              </div>
            )}
            <div className="mb-6  w-1/3 slctbox_bt">
              <SelectComponent
                title={"Campaigning Status"}
                selected={selectedStatus}
                setSelected={setSelectedStatus}
                dataArr={status}
              />
            </div>
          </div>

          <div className="w-full flex justify-center space-x-3 ">
            <div className="mb-6  w-1/3 slctbox_bt">
              <SelectComponent
                title={"Campaigning Type"}
                selected={selectedType}
                setSelected={setSelectedType}
                dataArr={type}
              />
            </div>
            <div className="mb-6  w-1/3 slctbox_bt">
              <SelectComponent
                title={"Can User Hide"}
                selected={selectedCanUserHide}
                setSelected={setSelectedCanUserHide}
                dataArr={canUserHide}
              />
            </div>

            <div className="mb-6 w-1/3 slctbox1">
              <MultiSelectComponent
                title={"Campaining States"}
                selected={selectedStates}
                setSelected={setSelectedStates}
                dataArr={allStates ? allStates : []}
              />
            </div>
          </div>

          <div className="w-full flex justify-center space-x-1 ">
            <div className="mb-6  w-full">
              <InputComponent
                name={"Campaigning Description"}
                type={"text"}
                value={description}
                setValue={setDescription}
                required={true}
               
              />
            </div>
          </div>

          {/* The Conditional Code is here */}
          {isFeedBack && (
            <div>
              <div className="page-content">
                <div className="row">
                  <div>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open}>
                        <Box sx={style}>
                          <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Preview
                          </Typography>
                          {elements.map((element, idx) => {
                            return element.type === "select" ? (
                              <div className="col-md-12 my-2" key={idx}>
                                <div className="d-flex my-1">
                                  <span className="p-2">{element.label} </span>
                                </div>
                                <select className="my-2 py-1">
                                  <option value={"Select Options"}>
                                    Select Options
                                  </option>

                                  {element.options.map((opt) => (
                                    <option value={opt}>{opt}</option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div className="col-md-12 my-2" key={idx}>
                                <div className="d-flex my-1">
                                  <span className="p-2">{element.label} </span>
                                </div>
                                <input
                                  className="form-control"
                                  type={element.type}
                                  maxLength={element.maxLength}
                                  placeholder={element.label}
                                  required={element.required}
                                />
                              </div>
                            );
                          })}
                        </Box>
                      </Fade>
                    </Modal>
                  </div>

                  <div className="col-lg-12 grid-margin stretch-card chng_crd">
                    <div className="card">
                      <div className="card-body">
                        <div className="cmxform " id="signupForm">
                          <div className="form-group ">
                            <div>
                              <label className="text-capitalize font-weight-bold">
                                New Input
                              </label>
                            </div>
                            <div className="d-flex form_feed ">
                              <label>
                                <input
                                  type="text"
                                  className="border p-2 wdt"
                                  placeholder="Add Name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </label>
                              <label>
                                <input
                                  type="text"
                                  className="border p-2 wdt"
                                  placeholder="Add Label Name"
                                  value={lableName}
                                  onChange={(e) => setLableName(e.target.value)}
                                />
                              </label>
                              <label>
                                <input
                                  type="number"
                                  className="border p-2 wdt1"
                                  placeholder="Max Length"
                                  value={inputLength}
                                  onChange={(e) =>
                                    setInputLength(e.target.value)
                                  }
                                />
                              </label>
                              <label>
                                <select
                                  className="border p-2 wdt"
                                  value={inputType}
                                  onChange={(e) => handleInputTypeChange(e)}
                                >
                                  <option value={""}>Select Type</option>
                                  <option value={"date"}>Date</option>
                                  <option value={"file"}>File</option>
                                  <option value={"checkbox"}>Checkbox</option>
                                  <option value={"text"}>Text</option>
                                  <option value={"select"}>Options</option>
                                </select>
                              </label>

                              {inputType === "select" ? (
                                <label>
                                  {usersTypes.map((key) => (
                                    <label key={key}>
                                      <span
                                        className="px-2 py-2 mx-2 btn btn-outline-danger"
                                        onClick={(e) =>
                                          removeFromUserTypes(e, key)
                                        }
                                      >
                                        {key}
                                      </span>
                                    </label>
                                  ))}

                                  <input
                                    type="text"
                                    className="border p-2 wdt1"
                                    placeholder="Add new option"
                                    value={newUserValue}
                                    onChange={(e) =>
                                      setNewUserValue(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.keyCode == 13) {
                                        let oldUserTypes = [...usersTypes];
                                        oldUserTypes.push(newUserValue);
                                        setUsersTypes(oldUserTypes);
                                        setNewUserValue("");
                                      }
                                    }}
                                  />
                                </label>
                              ) : (
                                <label></label>
                              )}
                              <label className="d-flex">
                                <input
                                  type="checkbox"
                                  className="px-1 py-1"
                                  checked={required ? "checked" : ""}
                                  onChange={() => setRequired(!required)}
                                />{" "}
                                <span className="px-1 py-1 ">Required</span>
                              </label>

                              <button
                                type="button"
                                className="btn btn-outline-primary mtms"
                                onClick={() => {
                                  setReRender(!reRender);
                                }}
                              >
                                Add
                              </button>
                            </div>

                            {elements.map((element, idx) => {
                              return element.type === "select" ? (
                                <div className="my-2 show_wt" key={idx}>
                                  <div className="d-flex my-1 justify-between">
                                    <span className="text_show">
                                      {element.label}{" "}
                                    </span>
                                    <span
                                      className="close_show text-danger"
                                      onClick={(e) =>
                                        handleDeleteElement(e, idx)
                                      }
                                    >
                                      x
                                    </span>{" "}
                                  </div>
                                  <select className="selctws ">
                                    <option value={"Select Options"}>
                                      Select Options
                                    </option>

                                    {element.options.map((opt) => (
                                      <option value={opt}>{opt}</option>
                                    ))}
                                  </select>
                                </div>
                              ) : (
                                <div className="show_wt my-2" key={idx}>
                                  <div className="d-flex my-1 justify-between">
                                    <span className="text_show">
                                      {element.label}{" "}
                                    </span>
                                    <span
                                      className="close_show text-danger"
                                      onClick={(e) =>
                                        handleDeleteElement(e, idx)
                                      }
                                    >
                                      x
                                    </span>
                                  </div>

                                  <input
                                    className="form-control ckbox_show"
                                    type={element.type}
                                    maxLength={element.maxLength}
                                    placeholder={element.label}
                                    required={element.required}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=" flex  justify-center m-auto pt-3 p-auto ">
            {isUpdate ? (
              <button
                className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={() => updateCampaign()}
              >
                Update
              </button>
            ) : (
              <button
                className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={() => addCampaign()}
              >
                Add
              </button>
            )}
          </div>
        </div>
      </div>
    </div></div>
  );
};

export default AddCampaign;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
