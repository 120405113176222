import React, { useEffect, useRef, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileMutation } from "../../../features/tenant/ManualRewarding/ManualRewardingApi";
import nodata from "../../../assests/tenant/images/nodata.jpg";
import toast from "react-hot-toast";
import { useAccountTransferMutation } from "../../../features/tenant/AccountTransfer/accountTransferApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const AccountTransfer = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [oldMobile, setOldMobile] = useState(null);
  const mobileType = useRef(1);
  const isSubmit = useRef(false);
  const [mobileDetails, setMobileDetails] = useState(null);
  const [newMobile, setNewMobile] = useState(null);
  const [newMobileDetails, setNewMobileDetails] = useState(null);
  // console.log(isSubmit);

  useEffect(() => {
    const addAcoountTransferPermission = hasPermission("addAccountTransfer");
    if (!addAcoountTransferPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
  }, [])
  

  const [
    accountTransfer,
    {
      data: accountTransferData,
      error: accountTransferError,
      isLoading: accountTransferisLoading,
    },
  ] = useAccountTransferMutation();

  const [
    getUserProfile,
    {
      data: getUserProfileData,
      error: getUserProfileError,
      isLoading: getUserProfileisLoading,
    },
  ] = useGetUserProfileMutation();

  function validateMobileNumber(mobileNumber) {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  }

  const handleGetMobileDetails = (e, mobile) => {
    console.log("mobile", mobile);
    console.log("mobiletype : ", mobileType);
    if (!validateMobileNumber(mobile)) {
      toast.error("Invalid Mobile Number", {
        id: "invalidMobileNumber",
      });
      return;
    }
    getUserProfile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      mobile: mobile,
    });
  };

  const handleAccountTransfer = () => {
    if (mobileDetails != null && newMobileDetails == null) {
      toast(
        (t) => (
          <span>
            <h6>Are you sure you ?</h6>
            <div className="mt-3 d-flex">
              <button
                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                onClick={() => {
                  accountTransfer({
                    token: sessionStorage.getItem("access_token"),
                    tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                      .tenant_id,
                    body: {
                      old_mobile: oldMobile,
                      new_mobile: newMobile,
                    },
                  });
                  toast.dismiss(t.id);
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                No
              </button>
            </div>
          </span>
        ),
        {
          id: "logout",
        }
      );
    } else {
      toast.error("new mobile already exist ");
    }
  };

  useEffect(() => {
    if (accountTransferData) {
      toast.success(accountTransferData.message, {
        id: "accountTransferData",
      });
    }

    if (accountTransferError) {
      toast.error(accountTransferError.data.message, {
        id: "notfound",
      });
    }
    isSubmit.current = false;
  }, [accountTransferData, accountTransferError]);

  

  useEffect(() => {
    if (getUserProfileData) {
      console.log("getUserProfileData", getUserProfileData);
      console.log("mobile type", mobileType);
      //   console.log("mobile type", mobileType );
      if (mobileType.current == 1) {
        console.log("old mobile");
        setMobileDetails(getUserProfileData.body);
      } else if (mobileType.current == 2) {
        console.log("new mobile");
        toast.error("mobile already exist ", {
          id: "already exist ",
        });
        setNewMobileDetails(getUserProfileData.body);
      }
    }

    if (getUserProfileError) {
      console.log("getUserProfileDat error ", getUserProfileData);
      console.log("mobile type error", mobileType);

      if (mobileType.current == 1) {
        setMobileDetails(null);
        toast.error("mobile number not found", {
          id: "notfound",
        });
      } else if (mobileType.current == 2) {
        console.log("new mobile");
        setNewMobileDetails(null);
      }
    }
  }, [getUserProfileData, getUserProfileError]);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Account Transfer
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className=" bg-white shadow rounded-lg p-3">
            <div className="w-full">
              <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                Mobile
              </label>
              <div className="flex justify-between gap-3 mb-3">
                <div className="w-full ">
                  <div className="relative searchin">
                    <input
                      type="number"
                      id="default-search"
                      className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile"
                      required
                      value={oldMobile}
                      onChange={(e) => {
                        const value = e.target.value;
                        
                        if (/^\d{0,10}$/.test(value)) {
                            setOldMobile(value);
                        }
                    }}
                      maxLength={10}
                    />
                  </div>
                </div>
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={(e) => {
                    mobileType.current = 1;
                    handleGetMobileDetails(e, oldMobile);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            {mobileDetails != null && Object.keys(mobileDetails).length > 0 ? (
              <div className="mt-3 row">
                <div className="col-sm-6">
                  <div className="flex gap-3  mb-3">
                    <div className="w-100">
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            App User ID
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.id}
                          </td>
                        </tr>

                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Name
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.name}
                          </td>
                        </tr>

                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            User Type
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.user_type}
                          </td>
                        </tr>

                        <tr className="text-smborder border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Mobile
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.mobile}
                          </td>
                        </tr>

                        <tr className="text-smborder border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Gender
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.gender}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      New Mobile
                    </label>
                    <div className="flex justify-between gap-3 mb-3">
                      <div className="w-full ">
                        <div className="relative searchin">
                          <input
                            type="number"
                            id="default-search"
                            className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Mobile"
                            required
                            value={newMobile}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Ensure that the value is numeric and at most 10 characters long
                              if (/^\d{0,10}$/.test(value)) {
                                  setNewMobile(value);
                              }
                          }}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={(e) => {
                          mobileType.current = 2;
                          isSubmit.current = true;
                          handleGetMobileDetails(e, newMobile);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  {mobileDetails != null && newMobileDetails == null && (
                    <div className="w-full">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Old Mobile : {oldMobile}
                      </label>
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        New Mobile : {newMobile}
                      </label>

                      {isSubmit.current && (
                        <button
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          onClick={handleAccountTransfer}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <span className="block text-center  text-lg font-bold text-gray-300">
                <img
                  src={nodata}
                  alt=""
                  className="m-auto max-w-full max-h-full"
                />
                No Data Available
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTransfer;
