import React, { useEffect, useState } from 'react'
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';
import SelectComponent from '../../../components/Tenant/SelectComponent/SelectComponent';
import { useGetAllStateMutation } from '../../../features/tenant/locations/locations.api';
import { useDashboardStatsForJqrMutation } from '../../../features/tenant/JqrSportsApi/jqrSportsApi';
import MultiSelectComponent from '../../../components/Tenant/MultiSelectComponent/MultiSelectComponent';
import toast from 'react-hot-toast';

const JqrDashboard = () => {

    const currentDate = new Date().toISOString().split("T")[0];
    const [startDate, setStartDate] = useState('2024-01-01');
    const [endDate, setEndDate] = useState(currentDate)

    const [allStates, setAllStates] = useState([]);

    const [originalStaes, setOriginalStaes] = useState([]);

    const [selectedStates, setSelectedStates] = useState([]);

    const [statsData, setStatsData] = useState(null);
    const [
        getAllState,
        {
            data: getAllStateData,
            isLoading: getAllStateIsLoading,
            error: getAllStateError,
        },
    ] = useGetAllStateMutation();


    const [
        fetchDashboardStats,
        {
            data: dashboardStatsData,
            isLoading: dashboardStatsIsLoading,
            error: dashboardStatsError,
        },
    ] = useDashboardStatsForJqrMutation();


    useEffect(() => {
        //   const hasViewPermission = hasPermission("productHierarchy");
        //   if (!hasViewPermission) {
        //     navigate(`/genefied/${getVendorName()}/`);
        //   }

        getAllState({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        });
    }, []);

    useEffect(() => {
        if (getAllStateData) {
            let states = getAllStateData.body.map((x) => {
                return {
                    id: x.id,
                    name: x.state,
                };
            });
            setOriginalStaes(states);
            const statesArr = [{ id: "0", name: "All" }, ...states]
            setAllStates(statesArr);
        }
    }, [getAllStateData, getAllStateError]);


    const fetchStatsData = () => {
        console.log("fetchStatsData", selectedStates, startDate, endDate);
        if (selectedStates.length > 0) {
            const finalStates = selectedStates.map((s) => s.name);
            toast.loading("Fetching stats...", { id: "fetchingStats" });
            fetchDashboardStats({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: {
                    states: finalStates,
                    startDate: startDate,
                    endDate: endDate,
                    userTypeId: 2
                }
            })
        }
    }


    useEffect(() => {
        if (selectedStates) {
            console.log("selectedStates", selectedStates);
            if (selectedStates.length > 0) {
                let found = false;
                selectedStates.forEach(state => {
                    if (state.id == "0")
                        found = true;
                })
                if (found) {
                    setSelectedStates(originalStaes);
                }
            }
        }
    }, [selectedStates])



    useEffect(() => {

        if (dashboardStatsData && dashboardStatsData.body) {
            toast.dismiss();
            console.log("selectedStates", dashboardStatsData)
            setStatsData(dashboardStatsData.body);
        }

        if (dashboardStatsError) {
            toast.dismiss();
            console.error("dashboardStatsError", dashboardStatsError)
            toast.error("Something went wrong", { id: "dashboardStatsError" })
        }
    }, [dashboardStatsData, dashboardStatsError])


    const clearFilters = () => {
        setSelectedStates([]);
        setStartDate('2024-01-01'); setEndDate(currentDate);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "1":
                return "Active";
            case "2":
                return "Inactive";
            default:
                return "Unknown";
        }
    };
    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b flex justify-between items-center bg-white border-gray-300 pl-6 py-1 shadow-sm text-xl font-bold">
                        <span>
                        Stats
                            <LinksComponent />
                        </span>
                    </div>


                    <div className="p-4 bg-gray-50 rounded-lg shadow-sm">
                        <div className="w-full max-w-5xl flex justify-start items-end space-y-4 md:space-y-0 md:space-x-4">
                            <div className="w-2/4">
                                <MultiSelectComponent
                                    title={"Select States"}
                                    placeholder={"Select States ..."}
                                    selected={selectedStates}
                                    setSelected={setSelectedStates}
                                    dataArr={allStates ? allStates : []}
                                />
                            </div>

                            <div className="w-2/4 flex space-y-4 md:space-y-0 md:space-x-4">
                                <div className="w-1/2">
                                    <label
                                        htmlFor="start-date"
                                        className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                        Start Date
                                    </label>
                                    <input
                                        id="start-date"
                                        type="date"
                                        className="w-full px-3 py-2 shadow-md text-sm border rounded-lg text-gray-900 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <label
                                        htmlFor="end-date"
                                        className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                        End Date
                                    </label>
                                    <input
                                        id="end-date"
                                        type="date"
                                        className="w-full px-3 py-2 shadow-md text-sm border rounded-lg text-gray-900 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="End Date"
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                            <button
                                className="mt-0.5 text-white bg-blue-500 hover:bg-blue-600  font-medium rounded-lg text-sm px-3 py-2 text-center "
                                onClick={() => fetchStatsData()}
                            >
                                Search
                            </button>
                            <button
                                className="mt-0.5 text-white bg-red-400 hover:bg-red-600  font-medium rounded-lg text-sm px-3 py-2 text-center "
                                onClick={() => clearFilters()}
                            >
                                Clear
                            </button>
                        </div>
                    </div>

                    <div className="px-4 my-2 bg-gray-50  ">

                        <div className="px-2 pt-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            {statsData && statsData?.users.map((user, index) => (
                                user.status != 0 &&
                                <div
                                    key={index}
                                    className="bg-white shadow-sm max-w-72 rounded-lg p-4 border border-gray-200"
                                >
                                    <p className="font-bold text-xl text-gray-800 capitalize">
                                        {user.user_type}
                                    </p>
                                    <p className="text-xl text-gray-600 mt-2">
                                        Count : {user.user_count}

                                    </p>
                                    <p className="text-xl text-gray-600 mt-2">
                                        <span className="">Status :</span>{" "}
                                        <span
                                            className={`px-2 py-1 rounded-xl text-md ${user.status === "1"
                                                ? "bg-green-100 text-green-600"
                                                : user.status === "2"
                                                    ? "bg-red-100 text-red-600"
                                                    : "bg-gray-100 text-gray-600"
                                                }`}
                                        >
                                            {getStatusLabel(user.status)}
                                        </span>
                                    </p>


                                </div>
                            ))}
                        </div>

                        <div className="px-2 py-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            {statsData && statsData?.scanning.map((data, index) => (
                                <>
                                    <div
                                        key={index}
                                        className="bg-white shadow-sm max-w-72 rounded-lg p-4 border border-gray-200"
                                    >
                                        <h2 className="text-xl font-bold text-gray-800 capitalize">
                                            Unique Retailers
                                        </h2>
                                        <p className="text-xl text-gray-600 mt-2">
                                            {data.distinct_user_count}

                                        </p>

                                    </div>

                                    <div
                                        key={index}
                                        className="bg-white shadow-sm max-w-72 rounded-lg p-4 border border-gray-200"
                                    >
                                        <h2 className="text-xl font-bold text-gray-800 capitalize">
                                            Total Pairs
                                        </h2>
                                        <p className="text-xl text-gray-600 mt-2">
                                            {data.distinct_qr_count}

                                        </p>

                                    </div>

                                    <div
                                        key={index}
                                        className="bg-white shadow-sm max-w-72 rounded-lg p-4 border border-gray-200"
                                    >
                                        <h2 className="text-xl font-bold text-gray-800 capitalize">
                                            Total MRP
                                        </h2>
                                        <p className="text-xl text-gray-600 mt-2">
                                            {data.total_mrp}

                                        </p>

                                    </div>
                                </>


                            ))}
                        </div>


                    </div>

                </div>

            </div>
        </>
    );
};

export default JqrDashboard;