import React, { Fragment, useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useFetchAllDeletedUserByUserTypeMutation,
  useFetchUserByUserTypeMutation,
  useUpdateAppUserStatusMutation,
} from "../../../features/tenant/appUsers/appUsersApi";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { HiOutlinePlus } from "react-icons/hi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import {
  useAddbanUnbanMutation,
  useFetchAllbanUnbanMutation,
} from "../../../features/tenant/banUnban/banUnbanApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

const AppBan = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchAppUserByUserType, { data: appUsersData, error: appUsersError }] =
  useFetchAllDeletedUserByUserTypeMutation();
  const [newRow, setNewRow] = useState();
  const [
    fetchAppUserByUserTypeCsv,
    { data: appUsersCsvData, error: appUsersCsvError },
  ] = useFetchAllDeletedUserByUserTypeMutation();

  const [
    updateAppUserStatus,
    {
      data: updateAppUserStatusData,
      error: updateAppUserStatusError,
      isLoading: updateAppUserStatusIsLoading,
      isError: updateAppUserStatusIsError,
    },
  ] = useUpdateAppUserStatusMutation();

  const [
    FetchAllbanUnban,
    {
      data: FetchAllbanUnbanData,
      error: FetchAllbanUnbanError,
      isLoading: FetchAllbanUnbanIsLoading,
      isError: FetchAllbanUnbanIsError,
    },
  ] = useFetchAllbanUnbanMutation();

  const [
    AddbanUnban,
    {
      data: AddbanUnbanData,
      error: AddbanUnbanError,
      isLoading: AddbanUnbanIsLoading,
      isError: AddbanUnbanIsError,
    },
  ] = useAddbanUnbanMutation();
  const [prevPage, setPrevPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];

  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [remarks, setRemarks] = useState();
  const initialRequest = {
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(() => {
    if (updateAppUserStatusData) {
      toast.success("Status Updated Successfully!");
      fetchAppUserByUserTypeFunction();
      fetchAppUserByUserTypeCsvFunction();
      return;
    }

    if (updateAppUserStatusError) {
      toast.error("Error in Updating Status");
      return;
    }
  }, [updateAppUserStatusData, updateAppUserStatusError]);

  useEffect(() => {
    fetchAppUserByUserTypeFunction();
    fetchAppUserByUserTypeCsvFunction();
  }, [location.pathname, limit, offset, userTypesArray]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const fetchAddbanUnbanFunction = (row) => {
    console.log(row, "Row HERE");
    if (row) {
      AddbanUnban({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          appUserId: row?.id,
          userTypeId: row?.user_type_id,
          userType: row?.user_type,
          userName: row?.name,
          userMobile: row?.mobile,
          status: row?.status,
          remarks,
        },
      });
    }
  };

  const fetchFetchAllbanUnbanFunction = (row) => {
    FetchAllbanUnban({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: { app_user_id: row.id },
    });
  };

  const fetchAppUserByUserTypeFunction = () => {
    fetchAppUserByUserType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };

  const fetchAppUserByUserTypeCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAppUserByUserTypeCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: zoneId,
    });
  };
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.mobile === "" &&
      request.user_type_id === userTypeId &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchAppUserByUserTypeFunction();
      fetchAppUserByUserTypeCsvFunction();
    }
  }, [request, userTypeId]);

  useEffect(() => {
    if (AddbanUnbanData) {
      if (newRow) {
          handleStatusUpdateFun(newRow);
      }
    }
    if (AddbanUnbanError) {
      toast.error("Error in Banning User");
    }
  }, [AddbanUnbanData, AddbanUnbanError]);

  const handleStatuChange = (row) => {
    if (!remarks) {
      toast.error("Please enter Some remarks", {
        id: "remarksError",
      });
      return;
    }
    fetchAddbanUnbanFunction(row);
  };
  const handleStatusUpdateFun = (row) => {
    updateAppUserStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: Number(row.id),
      data: {
        status: 1,
      },
    });
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => (
        <div>
            {row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      cell: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "UserType",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Gender",
      cell: (row) => row.gender,
      sortable: true,
    },
    {
      name: "City",
      cell: (row) => row.city,
      sortable: true,
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
    },
    {
      name: "Block/Unblock Date",
      cell: (row) => (
        <div className="flex">
          <span className="mr-2 w-fit">
            {new Date(row.updated_at).toLocaleDateString()}
          </span>

          <span className="w-fit">
            {new Date(row.updated_at).toLocaleTimeString()}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Block/Unblock History",
      cell: (row) => (
        <button
          className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          onClick={() => {
            fetchFetchAllbanUnbanFunction(row);
            setShowModal2(true);
          }}
        >
          Block/Unblock History
        </button>
      ),
      sortable: true,
      minWidth: "220px",
    },
    {
      name: "Status",
      cell: (row) =>
        (
          <>
            <button
              onClick={() => {
                setNewRow(row);
                setShowModal1(true);
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              UnBan
            </button>
          </>
        ) 
    },
  ];

  const columns1 = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => (
        <div>
          <h2
            onClick={() =>
              navigate(`${row.id}`, {
                state: row,
              })
            }
            className=" underline text-blue-600"
          >
            {row.user_name}
          </h2>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Mobile",
      cell: (row) => row.user_mobile,
      sortable: true,
    },
    {
      name: "UserType",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Block/Unblock Date",
      cell: (row) => (
        <div className="flex">
          <span className="mr-2 w-fit">
            {new Date(row.updated_at).toLocaleDateString()}
          </span>

          <span className="w-fit">
            {new Date(row.updated_at).toLocaleTimeString()}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Status",
      cell: (row) => "Blocked",
    },
    {
      name: "Remarks",
      cell: (row) => row.remarks,
      minWidth: "200px",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            App Ban
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between items-start">
              {appUsersCsvData && appUsersCsvData?.body?.appUsersList && (
                <ExportCSV
                  data={appUsersCsvData && appUsersCsvData?.body?.appUsersList}
                  columnsToExport={[
                    "name",
                    "email",
                    "mobile",
                    "user_type",
                    "gender",
                    "city",
                    "pincode",
                    "district",
                    "state",
                    "remarks"
                  ]}
                />
              )}
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}</div>
                    </div>
                  </div>

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchAppUserByUserTypeFunction();
                            fetchAppUserByUserTypeCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <DataTableComponent
                  columns={columns}
                  data={appUsersData && appUsersData.body.appUsersList}
                  totalRows={appUsersData && appUsersData.body.total_app_users}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>

        {showModal1 && (
          <ModalComponent
            size={"xl"}
            data={"nadnandand"}
            show={showModal1}
            setShow={setShowModal1}
          >
            <div className="w-full p-4">
              <div className="w-full m-auto  justify-center ">
                <div className="flex gap-1 ">
                  <div className="pb-1  bottom-2 w-full mb-2">
                    <InputComponent
                      name={"Remarks"}
                      type={"text"}
                      value={remarks}
                      setValue={setRemarks}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => {
                    setShowModal1(false);
                    handleStatuChange(newRow);
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {showModal2 && FetchAllbanUnbanData?.body?.banUnbanList && (
          <ModalComponent
            size={"xl"}
            data={"nadnandand"}
            show={showModal2}
            setShow={setShowModal2}
          >
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns1}
                data={
                  FetchAllbanUnbanData && FetchAllbanUnbanData.body.banUnbanList
                }
                totalRows={FetchAllbanUnbanData?.body?.total_ban_unban_history}
              ></DataTableComponent>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default AppBan;
