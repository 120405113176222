import { rootApi } from "../../api/rootApi";

export const qrMappingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addQrMapping: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/unprotected/qr/update-qr`,
          headers: {
            "Content-Type": "application/json",
            apikey:params.apikey,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchQrMapping: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/unprotected/qr/qrList`,
          headers: {
            "Content-Type": "application/json",
            apikey:params.apikey,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    countQrMapping: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/unprotected/qr/qrListCount`,
          headers: {
            "Content-Type": "application/json",
            apikey:params.apikey,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useAddQrMappingMutation,useFetchQrMappingMutation,useCountQrMappingMutation
} = qrMappingApi;