import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ViewQr from "../../../components/Tenant/ViewQR/ViewQr";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { useCountQrMappingMutation, useFetchQrMappingMutation } from "../../../features/tenant/QrMapping/QrMappingApi";

const UpdateQrList = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    product_code: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: currentDate,
  };
  const initialRequestCount = {
    product_code: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);
  const [requestCount, setRequestCount] = useState(initialRequestCount);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Start Batch Running Code",
      cell: (row) => row.start_batch_running_code,
      sortable: true,
      width: "130px",
    },

    {
        name: "End Batch Running Code",
        cell: (row) => row.end_batch_running_code,
        sortable: true,
        width: "130px",
      },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },
   
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
        name: "Created By",
        cell: (row) => row.created_by_name,
        sortable: true,
      }
  ];
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    qrListDataFun,
    {
      data: qrListData,
      isLoading: qrListDataIsLoading,
      error: qrListDataError,
    },
  ] = useFetchQrMappingMutation();

  const [
    qrListDataCountFun,
    {
      data: qrListDataCount,
      isLoading: qrListDataCountIsLoading,
      error: qrListDataCountError,
    },
  ] = useCountQrMappingMutation();

  const fetchAllQrCodeFunction = () => {
    qrListDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      apikey: JSON.parse(sessionStorage.getItem("companyProfile")).api_key,
    });
  };
 

  const [
    qrLiCsvDataFun,
    {
      data: qrLiCsvData,
      isLoading: qrLiCsvDataIsLoading,
      error: qrLiCsvDataError,
    },
  ] = useFetchQrMappingMutation();


  useEffect(()=>{
    qrListDataCountFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: requestCount,
      apikey: JSON.parse(sessionStorage.getItem("companyProfile")).api_key,
    });
  },[requestCount])
  



  useEffect(() => {
    if (qrLiCsvDataError) {
      toast.error("Something went wrong", { id: "qrLiCsvDataError" });
    }
  }, [qrLiCsvDataError]);

  const qrListDownLoad = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    qrLiCsvDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      apikey: JSON.parse(sessionStorage.getItem("companyProfile")).api_key,
    });
  };

  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState(null);

  useEffect(() => {
    if (qrListData) {
       setMyList(qrListData && qrListData.body ? qrListData.body.qrsList : [])
    }
  }, [qrListData]);

  useEffect(() => {
    if (qrListDataCount) {
      
       setTotalRows(qrListDataCount && qrListDataCount.body ? qrListDataCount.body.total_qr : 0)
    }
  }, [qrListDataCount]);



  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Mapped Qrs List
            <LinksComponent />
          </div>

          <div className="p-3">

          <div className="flex gap-2">
          
          <button
            className="btn btn-outline-primary clear-both float-right  mb-2"
            onClick={() => {
              qrListDownLoad();
            }}
          >
            Export Csv
          </button>
         </div>
            <div className=" bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Code</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Code ..."
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_code: newValue };
                        });
                      }}
                    />
                  </div>
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Start Date"
                      value={request.startDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            startDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="End Date"
                      value={request.endDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            endDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2  w-56 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all "
                        onClick={() => {
                          fetchAllQrCodeFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={myList}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showQr && <ViewQr data={qrData} show={showQr} setShow={setShowQr} />}
      </div>
    </>
  );
};

export default UpdateQrList;
