import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { TiTimes } from "react-icons/ti";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";
import {
  useFetchRedemptionHistoryCountForTDSMutation,
  useFetchRedemptionHistoryForTDSMutation,
} from "../../../features/tenant/redeemptionHistory/redemptionHistoryApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const redemptionsType ={
    '1':"gift",
    '2':'cashback',
    "3":'coupon',
    '4':'dream gift'
}

const TdsOnRedemptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location at SMS template", location);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [csvData, setCsvData] = useState(null);

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [allZones, setAllZones] = useState([]);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });

  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);

  const currentDate = new Date().toISOString().split("T")[0];
  const [userZone, setUserZone] = useState({
    name: "All Zones",
    id: 0,
  });

  const initialRequest = {
    name: "",
    mobile: "",
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
    setUserZone({
      name: "All Zones",
      id: 0,
    });
  };

  const [
    fetchRedemptionHistory,
    { data: redemptionHistoryData, error: redemptionHistoryError },
  ] = useFetchRedemptionHistoryForTDSMutation();

  const [
    fetchRedemptionHistoryCsv,
    { data: redemptionHistoryCsvData, error: redemptionHistoryCsvError },
  ] = useFetchRedemptionHistoryForTDSMutation();
  const [
    fetchRedemptionHistoryCount,
    { data: redemptionHistoryCountData, error: redemptionHistoryCountError },
  ] = useFetchRedemptionHistoryCountForTDSMutation();

  const handleFetchRedemptionHistoryCsv = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchRedemptionHistoryCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: userZone.id,
    });
  };

  const handleFetchRedemptionHistory = () => {
    fetchRedemptionHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: userZone.id,
    });
  };

  const handleFetchRedemptionCountHistory = () => {
    fetchRedemptionHistoryCount({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: request,
        zoneId: userZone.id,
      });
  };
  useEffect(() => {
    const hasViewPermission = hasPermission("tdsCalculationOnRedemptions");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    handleFetchRedemptionCountHistory();
  }, []);

  useEffect(() => {
    handleFetchRedemptionHistory();
  }, [limit, offset]);

  useEffect(() => {
    if (redemptionHistoryData) {
      console.log("redemption history", redemptionHistoryData);
      setMyList(
        redemptionHistoryData.body && redemptionHistoryData.body.data
          ? redemptionHistoryData.body.data
          : []
      );
    }

    if (redemptionHistoryError) {
      console.log("redemtion error", redemptionHistoryError);
    }
  }, [redemptionHistoryData, redemptionHistoryError]);

  useEffect(() => {
    if (redemptionHistoryCountData) {
      console.log("redemptionHistoryCountData", redemptionHistoryCountData);

      setTotalRows(
        redemptionHistoryCountData.body && redemptionHistoryCountData.body.total
          ? redemptionHistoryCountData.body.total
          : 0
      );
    }

    if (redemptionHistoryCountError) {
      console.log("redemptionHistoryCountError", redemptionHistoryCountError);
    }
  }, [redemptionHistoryCountData, redemptionHistoryCountError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Email",
    //   cell: (row) => row.email,
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
        name: "Platform",
        cell: (row) => row.platform,
        sortable: true,
        width: "120px",
      },
    {
      name: "Redeemed Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Redemtion Type",
      cell: (row) => (redemptionsType[row.redemption_type]),
      sortable: true,
    },
    {
      name: "value",
      cell: (row) => row.value,
      sortable: true,
    },
    {
      name: "TDS Deducted",
      cell: (row) => row.tds_deducted,
      sortable: true,
    },
    {
      name: "Ref No",
      cell: (row) => row.ref_no,
      sortable: true,
    },

    {
        name: "Status",
        cell: (row) =>
          row.approval_status == '2' ? ( 
            <button
            className="text-red-500"
          >
            REJECTED
          </button>
          ) :
          row.approval_status == "3" ? (
            <button
             
              className="text-gray-500"
            >
            PENDING
               
            </button>
          ) : (
            <button   className="text-green-400">
              {/* <AiOutlineEdit className="mr-1" /> */}
              ACCECPTED
            </button>
          ) ,
  
        sortable: true,
      },
      {
        name: "tds_deducted_at",
        cell: (row) => row.tds_deducted_at ? new Date(row.tds_deducted_at).toLocaleString() : "",
        sortable: true,
      },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            TDS Calculation on Redemptions
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {redemptionHistoryCsvData &&
            redemptionHistoryCsvData?.body?.data && (
              <ExportCSV
                data={
                  redemptionHistoryCsvData &&
                  redemptionHistoryCsvData?.body?.data
                }
                columnsToExport={[
                  "name",
                  "email",
                  "mobile",
                  "user_type",
                  "points",
                  "redemption_type",
                  "ref_no",
                  "gift.gift[0].name"
                ]}
              />
            )} */}

            <div className="mb-2">
              <CustomExport
                fetchData={handleFetchRedemptionHistoryCsv}
                data={csvData}
                defaultColumnExport={[
                  "name",
                  "email",
                  "mobile",
                  "user_type",
                  "points",
                  "redemption_type",
                  "ref_no",
                  "gift.gift[0].name",
                ]}
                filename="redeem_request"
              />
            </div>
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Name
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile No..."
                      required
                      value={request.mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Zones
                    </label>
                    <div className="relative searchin">
                      <SelectComponent
                        placeholder={"Select Zone*"}
                        selected={userZone}
                        setSelected={setUserZone}
                        dataArr={allZones}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <div className="relative searchin gap-2 flex">
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        handleFetchRedemptionHistory();
                        handleFetchRedemptionCountHistory();
                      }}
                    >
                      Search
                    </button>

                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={""}
                data={myList}
                // loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TdsOnRedemptions;
